import { Injectable } from '@angular/core';
import { environment } from 'src/environment/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiUrls, UserRole } from '../../constants/constants';
import { AuthToketRequest } from '../../interfaces/Requests/AuthTokenRequest';
import { AuthTokenResponse } from '../../interfaces/Responses/AuthTokenResponse';
import { RoomInfo } from '../../interfaces/RoomInfo';
import { StartCallResponse } from '../../interfaces/Responses/StartCallResponse';
import { CallInfoResponse } from '../../interfaces/Responses/CallInfoResponse';
import { chatMessageResponse } from 'src/app/models/chatMessageResponse';
import { ChatMessage } from 'src/app/models/chatMessage';
import { FileHelperService } from '../file-helper.service';
import { getImageBase64Response } from 'src/app/models/getImageBase64Response';
import { Assistance } from '../../interfaces/assistance';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  constructor(private httpClient: HttpClient, private fileService: FileHelperService) { }

  loginToken(token: string) {
    var request = new AuthToketRequest()
    request.remoteEyeTokenLink = token;
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    return this.httpClient.post<AuthTokenResponse>(environment.apiUrl + ApiUrls.loginToken, request, { headers });
  }

  getRoomInfo(roomId: number, userId: number) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { "RoomId": roomId, "UserId": userId };
    return this.httpClient.post<RoomInfo>(environment.apiUrl + ApiUrls.roomInformation, request, { headers });
  }

  registerConnectionId(callId: number | null, connectionId: string | undefined, roomId: number, userId: number | null) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { "CallId": callId, "ConnectionId": connectionId, "RoomId": roomId, "UserId": userId };
    return this.httpClient.post(environment.apiUrl + ApiUrls.setConnectionId, request, { headers });
  }

  hangUp(roomId: number, userId: number, callId: number | null) {
    console.log("Sending Hang up for room : " + roomId);
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { "RoomId": roomId, "UserId": userId, "CallId": callId };
    return this.httpClient.post<CallInfoResponse>(environment.apiUrl + ApiUrls.hangUp, request, { headers });
  }

  startCall(roomId: number, userId: number, roleId: UserRole) {
    console.log("Sending start call  for room : " + roomId + " by :" + userId);
    var request = { "RoomId": roomId, "CallerId": userId, "CallerRole": roleId };
    return this.httpClient.post<StartCallResponse>(environment.apiUrl + ApiUrls.startCall, request);
  }

  sendGeneralInfo(message: string, info: string) {
    console.log("Sending general information " + ApiUrls.generalInfo);
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { "JsonString": info, "Message": message };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.generalInfo, request, { headers });
  }

  sendError(info: string) {
    console.log("Sending error " + ApiUrls.error);
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { "JsonString": info };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.error, request, { headers });
  }

  updateTags(callId: number | null, tags: string[], userId: number) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { 'CallId': callId, 'Tags': tags.join(','), 'UserId': userId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.updateTags, request, { headers });
  }

  updateVideoTags(videoId: number | undefined, tags: string[]) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { 'VideoId': videoId, 'Tags': tags.join(',') };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.updateVideoTags, request, { headers });
  }

  sendImage(callId: number, base64Img: string, userId: number, send: boolean, signalRConnectionId: string, roomId: number, IsLiveDraw: boolean, isHdImg: boolean) {
    console.log("Sending image " + ApiUrls.sendImage);
    var request = { "CallId": callId, "Base64Image": base64Img, "UserId": userId, "Send": send, "SignalrConnectionId": signalRConnectionId, "RoomId": roomId, "IsLiveDraw": IsLiveDraw, "IsHdImg": isHdImg };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.sendImage, request);
  }

  startLiveDrawing(callId: number, base64Img: string, userId: number, signalRConnectionId: string, roomId: number) {
    console.log("Sending image " + ApiUrls.sendImage);
    var request = { "CallId": callId, "Base64Image": base64Img, "UserId": userId, "SignalrConnectionId": signalRConnectionId, "RoomId": roomId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.startLiveDrawing, request);
  }

  startVideoRecording(callId: number, base64Image: string, sessionId: string | null, userId: number, signalRConnectionId: string, roomId: number) {
    console.log("Sending start video recording " + ApiUrls.startVideoRecording);
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');

    var request = { "CallId": callId, "Base64Image": base64Image, "SessionId": sessionId, "UserId": userId, "SignalrConnectionId": signalRConnectionId, "RoomId": roomId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.startVideoRecording, request, { headers });
  }

  stopVideoRecording(videoId: number | undefined, userId: number, signalRConnectionId: string, roomId: number) {
    console.log("Sending stop video recording " + ApiUrls.stopVideoRecording);
    var request = { "VideoId": videoId, "UserId": userId, "SignalrConnectionId": signalRConnectionId, "RoomId": roomId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.stopVideoRecording, request);
  }

  requestHDImage(callId: number, userId: number, signalRConnectionId: string, roomId: number) {
    console.log("request hd image " + ApiUrls.requestHDImage);
    var request = { "CallId": callId, "UserId": userId, "SignalrConnectionId": signalRConnectionId, "RoomId": roomId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.requestHDImage, request);
  }

  sendHdImage(callId: number, base64Img: string, userId: number, send: boolean, signalRConnectionId: string, roomId: number, senderSRId: string) {
    console.log("Sending HD image " + ApiUrls.sendHdImage);
    var request = { "CallId": callId, "Base64Image": base64Img, "UserId": userId, "Send": send, "SignalrConnectionId": signalRConnectionId, "RoomId": roomId, senderSignalRId: senderSRId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.sendHdImage, request);
  }

  SendChatMessage(signalRConnectionId: string, roomId: number, newMessage: ChatMessage, translate: boolean, translateLanguage: string, file: File | null, blob: Blob | null) {
    console.log("Sending Chat Message :" + environment.apiUrl + ApiUrls.sendChatMessage);
    let messageRequest = { "FromUserId": newMessage.userId, "RoomId": roomId, "ChatData": newMessage, "SignalRConnectionId": signalRConnectionId, "Translate": translate, "TranslateLanguage": translateLanguage };
    const formData = new FormData();
    try {
      if (file != null) {
        console.log("API append file", file);
        formData.append('file', file);
      }

      if (blob != null) {
        console.log("API append blob", blob);
        formData.append('file', blob, 'capture.png');
      }
    }
    catch (e) { console.error(e); }
    formData.append('request', JSON.stringify(messageRequest));
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.sendChatMessage, formData);
  }

  GetChatForRoom(roomId: number, userId: number) {
    console.log("Get Chat Messages " + ApiUrls.getChatMessages);
    let request = { UserId: userId, RoomId: roomId };
    return this.httpClient.post<chatMessageResponse[]>(environment.apiUrl + ApiUrls.getChatMessages, request);
  }

  getAudioFile(filePath: string) {
    console.log("Sending getAudioTranslated", ApiUrls.getAudioTranslated);
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { "FilePath": filePath };
    return this.httpClient.post<ArrayBuffer>(environment.apiUrl + ApiUrls.getAudioTranslated, request, { headers, responseType: 'arraybuffer' as 'json' });
  }

  getUpdatedToken(userId: number, roomId: number) {
    var request = { "RoomId": roomId, "UserId": userId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.updateToken, request);
  }

  callNotification(userId: number, roomId: number, signalRConnectionId: string, active: boolean) {
    var request = { "RoomId": roomId, "CallerId": userId, "SignalRId": signalRConnectionId, "Active": active };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.callNotification, request);
  }

  callNotificationResponse(roomId: number, signalRConnectionId: string, accepted: boolean) {
    var request = { "RoomId": roomId, "SignalRId": signalRConnectionId, "Accepted": accepted };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.callNotificationResponse, request);
  }

  registerEvent(eventTag: string, name: string, message: string, userId: number) {
    var request = { "EventTag": eventTag, "Name": name, "Message": message, "UserId": userId };
    this.httpClient.post<any>(environment.apiUrl + ApiUrls.activityLogsAdd, request).subscribe();
  }

  deleteMessage(messageId: number, userId: number, roomId: number) {
    console.log("deleteMessage" + ApiUrls.deleteMessage);
    const request = { "Id": messageId, "UserId": userId, "RoomId": roomId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.deleteMessage, request);
  }

  getBase64Image(imageId: number, roomId: number, userId: number) {
    console.log("getBase64 Image" + ApiUrls.getBase64Image);
    const request = { "imageId": imageId, "userId": userId, "roomId": roomId };
    return this.httpClient.post<getImageBase64Response>(environment.apiUrl + ApiUrls.getBase64Image, request);
  }

  updateChatRoomUserName(roomId: number, userId: number, userName: string,participantId:number) {
    const url = environment.apiUrl + ApiUrls.updateChatRoomUserName;
    let request = { "RoomId": roomId, "UserId": userId, "UserName": userName, "ParticipantId": participantId};
    return this.httpClient.post<any>(url, request);
  }

  createAssitance(roomId: number, userId: number, assistance: Assistance) {
    const url = environment.apiUrl + ApiUrls.createAssistance;
    let request = { "RoomId": roomId, "UserId": userId, "Assistance": assistance };

    return this.httpClient.post<any>(url, request);
  }

  changeAssitanceState(roomId: number, userId: number, assistanceId: number | null, state: number) {
    const url = environment.apiUrl + ApiUrls.changeAssistanceState;
    let request = { "RoomId": roomId, "UserId": userId, "AssistanceId": assistanceId, "State": state };

    return this.httpClient.post<any>(url, request);
  }

  getAssistanceReport(assistanceId: number) {
    var request = { "AssistanceId": assistanceId };
    return this.httpClient.get(environment.remoteeyeUrl + ApiUrls.assistanceReport + "/" + assistanceId, { responseType: 'text' });
  }

  getAssistanceEmail(assistanceId: number) {
    var request = { "AssistanceId": assistanceId };
    return this.httpClient.post<any>(environment.remoteeyeUrl + ApiUrls.emailAssistance, request);
  }

  getAssistancesStates() {
    return this.httpClient.get<{ name: string; id: number }[]>(environment.apiUrl + ApiUrls.assistancesStates);
  }

}
