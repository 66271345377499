<div style="background-color: #161A29">

  <div id="imageContainer" class="divContainer">
    <canvas id="canvasSurface" class="divContainer" #canvas></canvas>
  </div>

  <div class="buttons-area" *ngIf="this.data.isExpert" #editImgContainer>
    <div class="round-button close-button" (click)="close()" #editImgButton>
      <img src="/assets/images/buttons/close.svg" alt="Icono">
    </div>
    <div *ngIf="!tempHidden" class="round-button capture-tool-button pencil" #editImgButton>
      <img src="/assets/images/buttons/capture/arrow.svg" alt="arrow" title="arrow">
    </div>
    <div *ngIf="this.editorMode != EDITOR_CONSTANTS.ImageReceived" class="round-button capture-tool-button" (click)="drawSquare()" #editImgButton>
      <img src="/assets/images/buttons/capture/shape.svg" alt="shape" title="shape">
    </div>
    <div *ngIf="this.editorMode != EDITOR_CONSTANTS.ImageReceived" class="round-button capture-tool-button" #editImgButton>
      <img src="/assets/images/buttons/capture/text.svg" alt="text" title="text" (click)="writeText()">
    </div>
    <div *ngIf="this.editorMode != EDITOR_CONSTANTS.ImageReceived" class="round-button capture-tool-button"
      [ngClass]="{'button-active' : this.isLiveStrokeDraw}" #editImgButton>
      <img src="/assets/images/buttons/capture/pencil.svg" alt="pencil" title="pencil" (click)="freeDrawing(null)">
    </div>
    <div *ngIf="this.editorMode != EDITOR_CONSTANTS.ImageReceived" class="round-button capture-tool-button" #editImgButton>
      <input type="color" value="{{this.fabricService ? this.fabricService.actualColor : startUpColor}}"
        (change)="updateColor($event) " />
      <img src="/assets/images/buttons/capture/colours.svg" alt="colours" title="colours">
    </div>
    <div *ngIf="this.editorMode != EDITOR_CONSTANTS.ImageReceived" class="round-button capture-tool-button" #editImgButton>
      <img src="/assets/images/buttons/capture/eraser.svg" alt="eraser" title="eraser" (click)="eraseAll()">
    </div>
    <div *ngIf="this.editorMode != EDITOR_CONSTANTS.Edit" class="round-button capture-tool-button" [ngClass]="{'button-active' :this.editorMode === EDITOR_CONSTANTS.LiveDraw}" #editImgButton>
      <img src="/assets/images/buttons/capture/live.svg" alt="live" title="live" (click)="startLiveDraw();">
    </div>
    <div *ngIf="showSaveButton" class="round-button capture-tool-button" #editImgButton>
      <img src="/assets/images/buttons/capture/save.svg" alt="save" title="save" (click)="saveAndClose(false)">
    </div>
    <div class="round-button capture-tool-button" #editImgButton>
      <img src="/assets/images/buttons/capture/send.svg" alt="send" title="send" (click)="saveAndClose(true)">
    </div>
  </div>
</div>
