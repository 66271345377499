import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageComponent } from './message/message.component';
import { ChatMessage } from '../../models/chatMessage';
import { FormsModule } from '@angular/forms';
import { UserDataService } from 'src/app/services/userData.service';
import { SignalRService } from 'src/app/services/communications/signalr.service';
import { ApiService } from 'src/app/services/communications/api.service';
import { ChatService } from 'src/app/services/chat.service';
import { Subscription } from 'rxjs';
import { chatFile } from 'src/app/models/chatFile';
import { CameraComponent } from '../cameraComponent/cameraComponent';
import { MatDialog } from '@angular/material/dialog';
import { RecordMediaService } from 'src/app/services/recordMedia.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core'; 
import { FileHelperService } from '../../services/file-helper.service';
import { ChatMessageType } from '../../constants/constants';


@Component({
  selector: 'directCall-chat',
  standalone: true,
  imports: [CommonModule, MessageComponent, FormsModule, MatTooltipModule, TranslateModule],
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss', '../../styles/components/panel.scss']
})

export class ChatComponent implements OnInit, OnDestroy {

  @Input() stickyMode: boolean = false;
  @Output() close = new EventEmitter<string>();

  @ViewChild('messagesContainer') private messagesContainer!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('textarea') textarea!: ElementRef;

  newMessage: ChatMessage;
  group: ChatMessage[][] = [];
  allowMembers = false;
  attachment: Blob | null = null; //se usa??

  chatMessageReceivedSubscription: Subscription;
  deleteChatMessageSubscription: Subscription;
  onEditedImageEvent: Subscription;
  recordingFailSubscription: Subscription;
  recordedBlobSubscription: Subscription;
  isRecordingVoice = false;

  attachFileTooltip: string = "TOOLTIP.ATTACH_FILE";
  takePhotoTooltip: string = "TOOLTIP.TAKE_PHOTO";
  recordAudioTooltip: string = "TOOLTIP.RECORD_AUDIO";

  constructor(private userDataService: UserDataService, private signalService: SignalRService, private apiService: ApiService, private chatService: ChatService,
    public dialog: MatDialog, private recordMediaService: RecordMediaService, private translate: TranslateService, private fileHelperService: FileHelperService) {

    var currentName = userDataService.Name != '' ? userDataService.Name! : '';
    if (currentName == '') {
      this.translate.get('GUEST').subscribe((text: string) => {
        currentName = text;
      });
    }

    this.newMessage = new ChatMessage(this.userDataService.Id!, '');
    this.newMessage.userName = currentName;
    console.debug("Chat component constructor...");

    this.chatMessageReceivedSubscription = this.signalService.onChatMessageReceived.subscribe((data => {
      console.log("onChatMessageReceived...", data);
      this.chatService.addMessageResponse(data);
      var messages: ChatMessage[] = this.chatService.getMessages();
      var lastMessage = messages.at(messages.length - 1);
      if (lastMessage != undefined) this.group = this.chatService.addMessageToGroup(this.group, lastMessage);
    }));

    this.deleteChatMessageSubscription = this.signalService.onDeleteChatMessage.subscribe((data => {
      console.log("onDeleteChatMessage...");
      this.deleteChatMessage(data);
    }));

    this.recordingFailSubscription = this.recordMediaService.recordingFailed().subscribe(() => console.log("Record failed"));

    this.recordedBlobSubscription = this.recordMediaService.getRecordedBlob().subscribe(data => {
      const file: File = new File([data.blob], data.title, { type: 'audio/webm' });
      var mediaType = this.fileHelperService.getFileType(file.name);
      this.newMessage.chatFile = new chatFile(-1, file.name, mediaType, "", file);
    });

    this.onEditedImageEvent = this.chatService.onImageEdited.subscribe(file => {
      console.log("Edited file", file);
      var mediaType = this.fileHelperService.getFileType(file.name);
      this.newMessage.chatFile = new chatFile(-1, file.name, mediaType, "", file);
    });

  }

  ngOnInit(): void {
    this.chatService.updateChat().subscribe({
      next: (result) => {
        this.group = this.chatService.groupMessagesByDate();
      },
      error: (err) => {
        console.error('Error al cargar los datos:', err);
      },
    });
  }


  ngOnDestroy(): void {
    console.debug("Chat received destroy subscription");
    this.chatMessageReceivedSubscription.unsubscribe();
    this.deleteChatMessageSubscription.unsubscribe();
    this.recordingFailSubscription.unsubscribe();
    this.recordedBlobSubscription.unsubscribe();
    this.onEditedImageEvent.unsubscribe();
  }

  scrollDown(sender: string): void {
    if (this.messagesContainer && this.messagesContainer.nativeElement) {
      try {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
        //console.log("Scroll down from " + sender);
      } catch (err) { }
    }

  }

  adjustTextareaHeight() {
    this.textarea.nativeElement.style.height = 'auto';

    const alturaDeseada = this.textarea.nativeElement.scrollHeight - 26;
    const alturaMaxima = 6 * 22; // Altura máxima de 6 líneas
    this.textarea.nativeElement.style.height = Math.min(alturaDeseada, alturaMaxima) + 'px';
  }

  resetTextareaHeight() {
    this.textarea.nativeElement.style.height = '34px'; //definido en el css
  }

  async addNewMessage(): Promise<void> {
    console.log("addnewmwssage attachment : ", this.attachment);

    if (this.newMessage.userId && (this.newMessage.message.trim() || this.newMessage.chatFile?.file)) {
      var user = this.userDataService.roomInfo.roomParticipants.find(p => p.id == this.userDataService.Id!);
      if (user == undefined) return;

      //this.newMessage.date = new Date();
      //this.newMessage.color = user.color;
      this.newMessage = new ChatMessage(this.userDataService.Id!, this.newMessage.message, new Date(), this.newMessage.userName, "", user.color, this.newMessage.chatFile, null, this.userDataService.roomInfo.assistanceId, ChatMessageType.User);

      if (this.fileInput) {
        this.fileInput.nativeElement.value = '';
      }

      this.group = this.chatService.groupMessagesByDate();
     
      console.log(" sending message : ", user.translateChatOn, user.translationLanguage, this.newMessage.chatFile);

      this.apiService.SendChatMessage(this.signalService.getConnectionId()!, this.userDataService.RoomId!, this.newMessage, user.translateChatOn, user.translationLanguage, this.newMessage.chatFile?.file ?? null, this.attachment ?? null).subscribe({
        next: data => {
          console.log("Send Chat message ok with id:", data.messageId);
          this.scrollDown("sendChatMessage");
        },
        error: error => {
          console.log("Send chat message error:" + error);
        }
      });

      this.newMessage.message = "";
      this.newMessage.chatFile = null;
      this.resetTextareaHeight();

    } else console.log('No había participante, o mensaje o imagen');

  }

  recordVoice(): void {
    if (this.isRecordingVoice) {
      this.isRecordingVoice = false;
      this.recordMediaService.stopRecordingAudio();
    } else {
      this.recordMediaService.startRecordingAudio();
      this.isRecordingVoice = true;
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    var mediaType = this.fileHelperService.getFileType(file.name);
    this.newMessage.chatFile = new chatFile(-1, file.name, mediaType, "", file);
  }

  clearAttachment() {
    this.newMessage.chatFile = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  addMessage(data: ChatMessage) {
    console.info("chat message added");
    this.chatService.addMessage(data);
    this.group = this.chatService.groupMessagesByDate();
  }

  closeChat() {
    this.close.emit();
  }

  takePhoto() {
    console.log("take photo");
    let dialogRef: any;
    dialogRef = this.dialog.open(CameraComponent, {
      disableClose: true
    });

    dialogRef!.afterClosed().subscribe((result: File) => {
      if (result !== null) {
        console.log("result file", result);
        var mediaType = this.fileHelperService.getFileType(result.name);
        this.newMessage.chatFile = new chatFile(-1, result.name, mediaType, "", result);
      }
      dialogRef = null;
    });
  }

  deleteChatMessage(data: number) {
    this.chatService.deleteMessage(data);
    this.group = this.chatService.groupMessagesByDate();
  }

  trackByGroup(index: number, messageGroup: ChatMessage[]): number {
    return index; 
  }

  trackByMessage(index: number, message: ChatMessage): number {
    return message.id!; 
  }
}

