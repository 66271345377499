import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { Assistance } from '../../../interfaces/assistance';
import { WhitespaceValidator } from '../../../validators/text.validator';


@Component({
  selector: 'assistance-modal',
  standalone: true,
  imports: [FormsModule, MatInputModule, MatFormFieldModule, MatButtonModule, MatDialogModule, TranslateModule, CommonModule, WhitespaceValidator],
  templateUrl: './assistance-modal.component.html',
  styleUrls: ['./assistance-modal.component.scss']
})

export class AssistanceModalComponent {

  assistance: Assistance = {
    name: '',
    comments: ''
  };

  constructor(public dialogRef: MatDialogRef<AssistanceModalComponent>) { }

  onSubmit(form: NgForm) {
    console.log("submit", form.valid);
    if (form.valid) this.dialogRef.close(this.assistance);
  }

}
