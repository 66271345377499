import { EventEmitter, Injectable } from '@angular/core';
import { UserDataService } from './userData.service';
import { ApiService } from './communications/api.service';
import { ChatMessage } from '../models/chatMessage';
import { chatMessageResponse } from '../models/chatMessageResponse';
import { chatFile } from '../models/chatFile';
import { FileHelperService } from './file-helper.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private messages: ChatMessage[] = [];
  private participants: Map<number, string> = new Map();
  translate: boolean = false;
  translateLanguage: string = "";
  readonly chatColors = ["#8c8c8c", "#64b4b4", "#f66a57", "#92c746", "#46C7BA", "#f09f29", "#9c27b0", "#3751b5", "#dfe749"];
  readonly chatBackgroundColors = ["#ffffff", "#46c7ba", "#f66a57", "#92c746", "#46C7BA", "#f09f29", "#9c27b0", "#3751b5", "#dfe749"];

  onChatReceived = new EventEmitter<chatMessageResponse[]>();
  onImageEdited = new EventEmitter<File>();

  constructor(private userDataService: UserDataService, private apiService: ApiService, private fileHelperService: FileHelperService) {
  }

  assignColorsToParticipants(userId: number): void {
    if (!this.userDataService.roomInfo) {
      console.log("assignColorsToParticipants no roomInfo!");
      return;
    }

    for (let i = 0; i < this.userDataService.roomInfo.roomParticipants.length; i++) {
      if (this.userDataService.roomInfo.roomParticipants[i].id != userId) {
        console.log("COLOR", i, this.userDataService.roomInfo.roomParticipants[i].id, this.chatColors[i % this.chatColors.length]);
        var index = i % this.chatColors.length;
        this.userDataService.roomInfo.roomParticipants[i].color = this.chatColors[(index == 0 ? index + 1 : index)];
      } else {
        this.userDataService.roomInfo.roomParticipants[i].color = this.chatColors[0]; //currentUser siempre se pinta blanco
      }

      this.participants.set(this.userDataService.roomInfo.roomParticipants[i].id, this.userDataService.roomInfo.roomParticipants[i].color!);
    }

  }

  getBackgroundColor(color: string) {
    var index = this.chatColors.findIndex(c => c == color);
    return this.chatBackgroundColors[index];
  }

  updateChat(): Observable<any> {
    return new Observable(observer => {
      console.log("update chat", this.userDataService.Id);
      this.apiService.GetChatForRoom(this.userDataService.RoomId!, this.userDataService.Id!).subscribe({
        next: (data: chatMessageResponse[]) => {
          if (data == null || data.length == 0) {
            console.log("No chat data ", data);
            return;
          }

          data.forEach((message: chatMessageResponse) => {
            message.chatData.date = new Date(message.chatData.date);
            this.addMessageResponse(message);
          });


          observer.next();
          observer.complete();

        },
        error: (error) => {
          observer.error(error);
          console.error(error);
        }
      });
    });
  }

  addMessageResponse(chatMessageResponse: chatMessageResponse) {
    //console.info("chat message added with addMessage Response");
    if (chatMessageResponse.chatFile != null && chatMessageResponse.chatFile?.fileId > 0) {
      //console.log("chat message has file ", chatMessageResponse.chatFile.fileName);
      //console.log("chat file extension is ", this.fileHelperService.getFileExtension(chatMessageResponse.chatFile.fileName), this.fileHelperService.getFileType(chatMessageResponse.chatFile.fileName));

      chatMessageResponse.chatData.chatFile = new chatFile(chatMessageResponse.chatFile?.fileId!, chatMessageResponse.chatFile?.fileName!, chatMessageResponse.chatFile?.mediaType!, chatMessageResponse.chatFile?.url, null);
    }

    //console.log(chatMessageResponse.chatData);
    this.addMessage(chatMessageResponse.chatData);
  }

  groupMessagesByDate(): ChatMessage[][] {
    const groups: { [key: string]: ChatMessage[] } = {};

    console.log("GROUP MESSAGES");
    console.log(this.messages);

    this.messages.forEach(message => {
      //también les asigno el color (preparado para cuando sean más de dos)
      if (message.color == undefined) message.color = this.getMessageColor(message.userId);

      const dateKey = new Date(message.date).toDateString();
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(message);
    });

    return Object.values(groups);
  }

  addMessageToGroup(groups: ChatMessage[][], message: ChatMessage): ChatMessage[][] {
    const dateKey = new Date(message.date).toDateString();
    var groupIndex = groups.findIndex(gr => gr.find(m => new Date(m.date).toDateString() == dateKey));
    if (groupIndex < 0) {
      groupIndex = 0;
      groups.push([]);
    }

    console.log("ADD MESSAGE TO GROUP");
    if (message.color == undefined) message.color = this.getMessageColor(message.userId);

    groups[groupIndex].push(message);
    return groups;
  }

  addMessage(mssg: ChatMessage) {
    this.messages.push(mssg);
  }

  getMessages() {
    return this.messages;
  }

  getMessageColor(userId: number) {
    if (!this.participants.has(userId)) {
      this.participants.set(userId, this.chatColors[this.participants.size]);
    }

    return this.participants.get(userId);
  }

  deleteMessage(data: number) {
    let oldMessages = this.messages;
    this.messages = [];
    console.log(data);
    const index = oldMessages.findIndex(o => o.id == data);
    oldMessages.splice(index, 1);
    this.messages = oldMessages;
  }

  imageEdited(result: File) {
    this.onImageEdited.emit(result);
  }

}


