import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'directCall-guest-name-ask-modal',
  standalone: true,
  imports: [FormsModule, MatInputModule, MatFormFieldModule, ReactiveFormsModule, MatButtonModule, MatDialogModule, TranslateModule, CommonModule],
  templateUrl: './guest-name-ask-modal.component.html',
  styleUrls: ['./guest-name-ask-modal.component.scss']
})

export class GuestNameAskModalComponent {

  userEmail = new FormControl('', [Validators.required, Validators.email, this.isValidEmail]);

  constructor(public dialogRef: MatDialogRef<GuestNameAskModalComponent>) { }

  acceptNameChanged() {
    if (this.userEmail.valid) this.dialogRef.close(this.userEmail.value);
  }

  isValidEmail(control: AbstractControl): { [key: string]: any } | null {
    var regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-za-z\-0-9]+\.)+[a-za-z]{2,}))$/;
    return regexp.test(control.value) ? null : { completeEmail: true };
  }

}
