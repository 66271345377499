import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatMessage } from 'src/app/models/chatMessage';
import { MatDialog } from '@angular/material/dialog';
import { MediaPreviewComponent } from './media-preview/media-preview.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '../../../pipes/pipes.module';
import { FileHelperService } from 'src/app/services/file-helper.service';
import { UserDataService } from 'src/app/services/userData.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChatService } from '../../../services/chat.service';
import { ApiService } from 'src/app/services/communications/api.service';
import { ImageEditorResultData } from 'src/app/interfaces/imageeditor-resultdata';
import * as constants from 'src/app/constants/constants';
import { ImageEditorComponent } from '../../image-editor/image-editor.component';
import { SafeUrl } from '@angular/platform-browser';
import { ApiUrls, ChatMessageType, MediaType } from 'src/app/constants/constants';
import { environment } from '../../../../environment/environment';
import { NotificationSnackComponent } from '../../notificationSnak/notificationSnack.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'directCall-chat-message',
  standalone: true,
  imports: [CommonModule, MatDialogModule, PipesModule, TranslateModule],
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})

export class MessageComponent implements OnInit, AfterViewInit {

  @Input() chatMessage: ChatMessage = new ChatMessage(-1, '');
  @Output() messageLoaded = new EventEmitter<void>();

  currentUserId: number;
  roomId: number;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  src?: SafeUrl | File;
  canDelete: boolean = false;
  mediaTypeEnum = MediaType;
  chatMessageTypeEnum = ChatMessageType;
  remoteeyeUrl: string = environment.remoteeyeUrl;
  openReportTooltip: string = "TOOLTIP.OPEN_REPORT";
  sendReportByEmailTooltip: string = "TOOLTIP.SEND_REPORT_BY_EMAIL";

  constructor(public dialog: MatDialog, public fileHelperService: FileHelperService, public userDataService: UserDataService, private chatService: ChatService, private apiServices: ApiService, private translate: TranslateService,
    private snackBar: MatSnackBar) {
    this.currentUserId = userDataService.Id!;
    this.roomId = userDataService.RoomId!;
  }

  ngOnInit() {
    this.color = this.chatMessage.color;
    this.backgroundColor = `rgba(${this.chatService.getBackgroundColor(this.chatMessage.color!)}, 0.1)`;
    this.borderColor = `1px solid ${this.chatMessage.color}`;
    this.src = this.chatMessage.chatFile?.url ?? "";

    //console.log('message Init: color', this.chatMessage.color);
    this.canDelete = this.currentUserId == this.chatMessage.userId;
  }

  ngAfterViewInit(): void {
    //console.log("After view message");
    this.messageLoaded.emit();
  }

  openMediaModal(url: string): void {
    this.dialog.open(MediaPreviewComponent, {
      height: 'auto',
      maxHeight: '90vh',
      data: url
    });
  }

  downloadFile() {
    window.open(this.chatMessage.chatFile?.url, '_blank');
  }

  openVideoUrl() {
    const url = this.chatMessage.chatFile?.url;
    window.open(url, '_blank');
  }

  deleteMessage() {
    console.log("delete messageid:", this.chatMessage.id);
    this.apiServices.deleteMessage(this.chatMessage.id!, this.currentUserId, this.roomId).subscribe({
      next: (data) => {
        console.log("delete message sent ok");
      },
      error: (err) => {
        console.error(err);
      }
    });
  }

  editImage(id: number, fileName: string) {
    console.log("File to edit", id);
    this.apiServices.getBase64Image(id, this.userDataService?.RoomId!, this.userDataService.Id!).subscribe({
      next: data => {
        console.log("Receved data", data);
        let dialogEditor = this.dialog.open(ImageEditorComponent, {
          panelClass: 'edit-image-dialog',
          data: { img: data.base64Image, mode: constants.ImageEditorModes.Edit, isExpert: this.userDataService.isExpert, isHdImage: false },
        });
        dialogEditor.afterClosed().subscribe((result: ImageEditorResultData) => {
          this.processEditedImage(result, "edited_" + fileName, data.mimeType);
        });
      },
      error: err => {
        console.error("Error image to edit" + JSON.stringify(err));

      }
    });
  }

  processEditedImage(result: ImageEditorResultData, filename: string, mime: string) {
    console.log("* Process image edit response");
    console.log("Close by :" + constants.ImageEditorActions[result.action]);
    switch (result.action) {
      case constants.ImageEditorActions.saveAndClose: {
        let file = this.fileHelperService.urltoFile(result.originalImageBase64, filename, mime).then((result: File) => {
          this.chatService.imageEdited(result);
        });
        break;
      }
      default: {
        console.log('The dialog was closed, nothing to do');
        break;
      }
    }
  }

  loaded() {
    console.log("Element loaded");
    this.messageLoaded.emit();
  }

  downloadReport() {
    this.apiServices.getAssistanceReport(this.chatMessage.assistanceId!).subscribe({
      next: htmlContent => {
        const newTab = window.open("", '_blank');
        if (newTab) {
          newTab.document.open();
          newTab.document.write(htmlContent);
          newTab.document.close();
        } else {
          this.translate.get('REPORT.DOWNLOAD_ERROR').subscribe((text: string) => {
            this.snackBar.openFromComponent(NotificationSnackComponent, {
              duration: constants.snackBarDurationInSeconds * 1000, data: { message: text }
            });
          });
        }
      },
      error: error => {
        console.log("Download report:" + error);
      }
    });
  }

  sendEmail() {
    let subject: string, startDate: string, endDate: string, summary: string;
    subject = startDate = endDate = summary = "";

    this.translate.get('EMAIL_ASSISTANCE_REPORT.SUBJECT').subscribe((text: string) => { subject = text; });
    this.translate.get('EMAIL_ASSISTANCE_REPORT.START_DATE').subscribe((text: string) => { startDate = text; });
    this.translate.get('EMAIL_ASSISTANCE_REPORT.END_DATE').subscribe((text: string) => { endDate = text; });
    this.translate.get('EMAIL_ASSISTANCE_REPORT.SUMMARY').subscribe((text: string) => { summary = text; });

    this.apiServices.getAssistanceEmail(this.chatMessage.assistanceId!).subscribe({
      next: data => {
        var body = `${startDate}: ${data.StartDate} \n ${endDate}: ${data.EndDate} \n ${summary}: ${data.Summary} \n `;
        const mailtoLink = `mailto:${this.userDataService.Name}?subject=${subject}&body=${encodeURIComponent(body)}`;
        window.location.href = mailtoLink;
      },
      error: error => {
        console.log("Send chat message error:" + error);
      }
    });

    
  }

}
