export const environment = {
  production: false,
  apiUrl: 'https://dev.api.directcall.remoteeye.com', //'https://localhost:7203', //
  remoteeyeUrl: 'https://dev.app.remoteeye.com', //'https://192.168.1.135:7219', //
  elmahKey: '8b99f31de9ac45caba59a5a58e54d7e2',
  elmahid: 'f9ac4331-fe65-48b5-b335-75f511cf4e7c',
  CallNotificationDurationSeconds: 50,
  tokenName: "skre45h",
  showGuestAskNameModal : true
};
