import { Injectable } from '@angular/core';
import { RoomInfo } from '../interfaces/RoomInfo';
import { ApiService } from './communications/api.service';
import { UserRole } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})

export class UserDataService {

  public Id: number | null = null;
  public Name: string | null = null;
  public RoomId: number | null = null;
  public Role: UserRole | undefined | null = null;
  public CallId: number | null = null;
  public ParticipantId: number | null = null;

  public streamsToTranslate: { [id: string]: OT.Stream } = { };

  public otCallToken: string | null = null;
  public otSession: string | null = null;
  public otApiKey: string | null = null;

  public roomInfo!: RoomInfo;
  public frontCameraDescriptions: string[] = ['back'];
  preferedCamera: string | undefined = undefined;

  constructor(private apiService:ApiService) {
    this.getDevices();
    this.streamsToTranslate = {};
   }

  public set(id: number | null, name: string, roleId: number | null, roomId: number | null, participantId: number | null) {
    this.Id = id;
    this.Name = name;
    this.RoomId = roomId;
    this.Role = roleId;
    this.ParticipantId = participantId;
  }

  isExpert() {
    return this.Role! == UserRole.Expert;
  }

  getTags() {
    var tags: string[] = [];
    if (this.roomInfo?.tags) tags = this.roomInfo.tags;
    return tags;
  }

  updateTags(tags: string[]) {
    if (this.roomInfo) this.roomInfo.tags = tags;
  }

  SetTranslatedStreamId(stream: OT.Stream) {
    console.log("SETTRANSLATEDSTREAMID", this.Id, stream.connection.connectionId);
    this.streamsToTranslate[stream.connection.connectionId] = stream;
  }

  GetTranslatedStreamId(userConnectionId: string) {
    return this.streamsToTranslate[userConnectionId];
  }

  DeleteTranslatedStreamId(stream: OT.Stream) {
    delete this.streamsToTranslate[stream.connection.connectionId];
  }

  selectDevice(devices: OT.Device[]) {
    console.log("Get devices: " + JSON.stringify(devices.filter(o => o.kind == 'videoInput')));
    this.apiService.sendGeneralInfo("Video Devices Detected", JSON.stringify(devices));
    for (let device of devices.filter(o => o.kind == 'videoInput')) {
      for (let str of this.frontCameraDescriptions!) {
        if (device.label.toLowerCase().includes(str.toLowerCase())) {
          this.preferedCamera = device.deviceId;
          break;
        }
      }
      if (this.preferedCamera)
        break;
    }
  }

  getDevices() {
    OT.getDevices((err, devices) => {
      if (err)
        console.error(err);
      if (devices) {
        this.selectDevice(devices);
      }
    });
  }


}
