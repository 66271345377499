import { Component, ElementRef, AfterViewInit, ViewChild, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as OT from '@opentok/client';
import { ExpertStream, TechnicianStream } from '../../constants/OpenTokConstants';
import * as constants from 'src/app/constants/constants';
import { UserDataService } from '../../services/userData.service';


@Component({
  selector: 'app-subscriber',
  standalone: true,
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.scss'],
  imports: [CommonModule]
})

export class SubscriberComponent implements AfterViewInit {
  @ViewChild('subscriberDiv') subscriberDiv!: ElementRef;
  @Input() session!: OT.Session;
  @Input() stream!: OT.Stream;
  @Input() isExpert: boolean = true;
  subscriber!: OT.Subscriber;
  opacity = 0;
  ExpertStreamName = ExpertStream;
  TechnicianStreamName = TechnicianStream;

  constructor(private userDataService: UserDataService) { console.log("subscriber constructor"); }

  ngAfterViewInit() {
    console.log("SUBSCRIBER COMPONENT", this.stream.name, this.isExpert, this.stream.videoType);
    console.log("DIV", this.subscriberDiv);

    if (this.stream.name == ExpertStream && !this.isExpert) {
      console.log("Expert");
      this.userDataService.SetTranslatedStreamId(this.stream);
      this.subscribeAudio(this.subscriberDiv.nativeElement, this.stream);
    }

    if (this.stream.name == TechnicianStream && this.isExpert) {
      console.log("Tech");
      this.userDataService.SetTranslatedStreamId(this.stream);
      
      if (this.stream.hasVideo) this.subscribe(this.subscriberDiv.nativeElement);
      else this.subscribeAudio(this.subscriberDiv.nativeElement, this.stream);
    }

    if (this.stream.videoType == constants.StreamVideoType.Screen) {
      console.log("ScreenShare");
      this.subscribe(this.subscriberDiv.nativeElement);
    }

    if (this.stream.name.includes("AUDIO_CONNECTOR")) {
      console.log("Audio connector", this.stream.videoType);
      this.subscribeAudioTranslate(this.subscriberDiv.nativeElement);
    }
  }


  subscribeAudio(htmlElement: string, stream: OT.Stream): void {

    this.subscriber = this.session.subscribe(stream, htmlElement, {
      width: "100%",
      fitMode: "cover",
      insertMode: 'append',
      showControls: false
    }, (err) => {
        if (err) {
          console.error("Error subscriber: " + err.message);
        }
        this.opacity = constants.OnVideoOpacity;
      }

    );

  }

  subscribe(htmlElement: string): void {
    this.subscriber = this.session.subscribe(this.stream, htmlElement, {
        insertMode: "replace",
        width: "100%",
        height: "100%",
        fitMode: 'contain'
      }, (err) => {
        if (err) {
          console.error("Error subscriber: " + err.message);
        }
        this.opacity = constants.OnVideoOpacity;
      }

    );

  }

  subscribeScreenShare(htmlElement: string): void {
    this.subscriber = this.session.subscribe(this.stream, htmlElement, {
        insertMode: "append",
        width: "100%",
        height: "100%",
        fitMode: 'cover'
      }, (err) => {
        if (err) console.error("Error subscriber: " + err.message);
      }
    );
  }

  subscribeAudioTranslate(htmlElement: string): void {
    this.subscriber = this.session.subscribe(this.stream, htmlElement, {
        width: "0",
        height: "0",
        fitMode: "cover",
        insertMode: 'append',
        showControls: false
      }, (err) => {
        if (err) console.error("Error subscriber: " + err.message);
      }
    );

    this.subscriber.on("destroyed", function () {
      console.log("** stream destroyed");
    });
  }

}
