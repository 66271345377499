<div *ngIf="chatMessage.type == chatMessageTypeEnum.User" class="message" [class.me]="chatMessage.userId == currentUserId">
  <div *ngIf="chatMessage.userName" class="participant" [style.color]="color">
    <p>{{chatMessage.userName}}</p>
  </div>
  <div *ngIf="!chatMessage.userName" class="participant" [style.color]="color">
    <p>{{'GUEST' | translate}}</p>
  </div>
  <div class="globe" [style.background]="backgroundColor" [style.border]="borderColor">

      <div *ngIf="chatMessage.chatFile != undefined" class="message-content">
          <div *ngIf="chatMessage.chatFile?.mediaType == mediaTypeEnum.Image"> <img [src]="src" (click)="openMediaModal(chatMessage.chatFile.url!)" (load)="loaded()"> </div>
          <div *ngIf="chatMessage.chatFile?.mediaType == mediaTypeEnum.Video"> <video [src]="src" controls (loadeddata)="loaded()"></video> </div>
          <div *ngIf="chatMessage.chatFile?.mediaType == mediaTypeEnum.Audio"> <audio [src]="src" controls (loadeddata)="loaded()"></audio> </div>
          <img *ngIf="chatMessage.chatFile?.mediaType == mediaTypeEnum.Document" src="/assets/images/filetypes/pdf_60x74.png" (click)="downloadFile()">
      </div>

    <div class="text">
        <p *ngIf="chatMessage.message" class="selectable-text" [innerHTML]="chatMessage.message | linkify | escapeHtml"></p>
    </div>

    <div class="text" *ngIf="chatMessage.message && chatMessage.translation">
      <p class="translate"><i [innerHTML]="chatMessage.translation | linkify | escapeHtml"></i></p>
    </div>

    <div class="bottom-line">
      <p> {{ chatMessage.date | date:'HH:mm' }}</p>
      <span *ngIf="canDelete" class="chat-icon" (click)="deleteMessage()" id="deleteMessage">
        <i class="material-icons small">delete_outline</i>
      </span>
      <span *ngIf="chatMessage.chatFile?.mediaType == mediaTypeEnum.Image" class="chat-icon" (click)="editImage(chatMessage.chatFile?.fileId!, chatMessage.chatFile?.fileName!)" id="editImage">
        <i class="material-icons small">edit</i>
      </span>
    </div>

  </div>
</div>

<div *ngIf="chatMessage.type == chatMessageTypeEnum.System" class="message system">
  <div class="globe">

    <div *ngIf="chatMessage.message != 'Report'" class="text">
      <p [innerHTML]="chatMessage.message | linkify | escapeHtml"></p>
    </div>

    <div *ngIf="chatMessage.message == 'Report'" class="buttons">

        <span id="assistanceReport" title="{{this.openReportTooltip | translate}}" class="btn btn-default middle" (click)="downloadReport()" target="_blank">
            <img src="/assets/images/chat/generate-report.svg" class="hvr-grow" style="width: 35px">
        </span>

        <span id="sendEmail" title="{{this.sendReportByEmailTooltip | translate}}" class="chat-icon" (click)="sendEmail()">
            <i class="material-icons send-email">mail</i>
        </span>

    </div>

    
  </div>
</div>
