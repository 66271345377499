import { ChatMessageType } from "../constants/constants";
import { chatFile } from "./chatFile";

export class ChatMessage {
  constructor(
    public userId: number,
    public message: string,
    public date: Date = new Date(),
    public userName?: string,
    public translation?: string,
    public color?: string,
    public chatFile: chatFile | null = null,
    public id: number | null = null,
    public assistanceId: number | null = null,
    public type: ChatMessageType = ChatMessageType.User
  ) { }
}





