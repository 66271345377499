import { Injectable } from '@angular/core';
import { ApiService } from './communications/api.service';
import { AuthTokenResponse } from '../interfaces/Responses/AuthTokenResponse';
import { RoomInfo } from '../interfaces/RoomInfo';


@Injectable({
  providedIn: 'root'
})

export class ConfigService {

  token: string | null = null;
  tokenType: string | null = null;
  roomInfo: RoomInfo | null = null;
  language!: string;


  constructor(private apiService: ApiService) {
    this.language = window.navigator.language.substring(0, 2);
  }

  public addSecurityToken(authTokenResponse: AuthTokenResponse) {
    this.token = authTokenResponse.token;
    this.tokenType = authTokenResponse.authType;
  }

  setBrowserLanguageAsCurrent() {
    this.language = window.navigator.language.substring(0, 2); //meter substring
  }
  changeLanguage(newLanguage: string) {
    this.language = newLanguage;
  }
}
