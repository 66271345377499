<div class="background">
  <div class="logo"></div>
  
  <div class="assistances" *ngIf="userDataService.roomInfo.assistanceId == undefined" title="{{ 'ASSISTANCES.OPEN_NEW' | translate }}">
    <button mat-button (click)="startAssistance()"><i class="material-icons">add</i></button>
  </div>

  <div class="assistances selectorStatus" *ngIf="userDataService.roomInfo.assistanceId != undefined && assistanceState != undefined" [title]="assistancesToolTip">
    <mat-form-field class="assistancesselect">
      <mat-select #stateSelect (selectionChange)="changeAssistanceStatus(stateSelect)" [(value)]="assistanceState" panelwidth="auto" [disabled]="!isExpert">
        <mat-option *ngFor="let state of assisatancesStatesOptions" [value]="state.value">{{ 'ASSISTANCES_STATES.' + state.key | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="translate">
    <mat-form-field class="translateselect">
      <mat-select #langSelect (selectionChange)="onSelectChange(langSelect.value)" [(value)]="currentLang" panelwidth="150px">
        <mat-option *ngFor="let lang of langs" [value]="lang">{{lang}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="link" (click)="getLink()"></div>

</div>
