import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WaitingroomComponent } from './components/waitingroom/waitingroom.component';
import { CallComponent } from './components/call/call.component';
import { LoaderComponent } from './components/loader/loader.component';
import { UserDataResolver } from './resolvers/UserDataResolver';


const routes: Routes = [
  { path: '', component: LoaderComponent },
  { path: 'waitingroom', component: WaitingroomComponent, resolve: { userData: UserDataResolver } },
  { path: 'call', component: CallComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

