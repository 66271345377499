import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'directCall-modal',
  standalone: true,
  imports: [MatButtonModule, MatDialogModule, TranslateModule, CommonModule],
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})

export class ModalComponent {

  title: string;
  message: string;
  addButtons: boolean;

  constructor(public dialogRef: MatDialogRef<ModalComponent>, @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string, addButtons: boolean }) {
    this.title = data.title;
    this.message = data.message;
    this.addButtons = data.addButtons;
  }

  close(): void {
    this.dialogRef.close();
  }

}
