import { Component, ElementRef, AfterViewInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as opentokConstants from '../../constants/OpenTokConstants';
import * as constants from 'src/app/constants/constants';
import { PublisherType } from 'src/app/constants/constants';
import { UserDataService } from '../../services/userData.service';
import { StartAudioConnectorResponse } from '../../interfaces/Responses/AudioConnectorResponse';
import { AudioConnectorService } from '../../services/audioConnector.service';



@Component({
  selector: 'app-publisher',
  standalone: true,
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.scss'],
  imports: [CommonModule]
})

export class PublisherComponent implements AfterViewInit {
  @ViewChild('publisherDiv') publisherDiv!: ElementRef;
  @Input() session!: OT.Session;
  @Input() isExpert: boolean = true;
  @Input() publisherType: number = PublisherType.Video;
  @Output() onScreenShareStop = new EventEmitter<any>();

  publisher!: OT.Publisher;
  publishing: Boolean;
  opacity = 0;


  constructor(private userDataService: UserDataService, private audioConnectorService: AudioConnectorService) {
    this.publishing = false;

    console.log("publisher constructor");
  }

  ngAfterViewInit() {
    //console.log("ngAfterViewInit", this.publisherType);
    //if (this.publisherType == PublisherType.Video) this.createVideoPublisher();
  }

  createVideoPublisher() {
    console.log("create Video publisher");
    console.log("init publisher", (this.isExpert ? opentokConstants.ExpertStream : opentokConstants.TechnicianStream));
    console.log("Prefered camera: " + this.userDataService.preferedCamera);

    this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, {
      height: "100%",
      width: "100%",
      insertMode: 'append',
      fitMode: 'contain',
      publishAudio: true,
      publishVideo: !this.isExpert,
      mirror: false,
      name: this.isExpert ? opentokConstants.ExpertStream : opentokConstants.TechnicianStream,
      resolution: "1280x720",
      videoSource: this.userDataService.preferedCamera,
      videoContentHint: 'detail'
    }, (err) => {
      if (err) {
        console.log("cancel publish video", err);
      }
    });

    this.publish();

    this.publisher.on("streamCreated", event => {
      console.log("STREAM CREATED VIDEO PUBLIHSER");

      this.userDataService.SetTranslatedStreamId(event.stream);

      if (this.userDataService.roomInfo.transcriptionsEnabled && this.userDataService.roomInfo.transcriptionsOn) {
        var participant = this.userDataService.roomInfo.roomParticipants.find(p => p.connectionId == event.stream.connection.connectionId)!;
        console.log("startAudioConnector - transcription", participant.id);
        if (participant != undefined) this.audioConnectorService.start(participant);
      }
      
    });

    this.publisher.on("streamDestroyed", event => {
      console.log("STREAM destroyed VIDEO PUBLIHSER");
    });
  }

  createScreenSharePublisher() {
    console.log("create Screen share publisher");

    this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, {
      insertMode: 'append',
      videoSource: 'screen',
      frameRate: 7,
      maxResolution: { width: 1280, height: 720 },
      mirror: false,
      videoContentHint: '', //possible options for this value: "", "motion", "detail", "text"
      name: "SHARESCREENSTREAMING"
    }, (err) => {
      if (err) {
        console.log("cancel screen sharing", err);
        this.onScreenShareStop.emit();
      }
      else {
        this.publish();
      }
    });

    this.publisher.on("streamCreated", event => {
      console.log("STREAM CREATED PUBLIHSER");
    });

    this.publisher.on("streamDestroyed", event => {
      console.log("STREAM DESTROYED PUBLIHSER");
      this.onScreenShareStop.emit();
    });
  }

  createAudioTranslatePublisher(data: StartAudioConnectorResponse) {
    var name = "AUDIO_CONNECTOR_";
    if (data.userRole == "Glass") name += "GLASS_";
    if (data.userRole == "guestExpert") name += "GUEST_" + data.userId + "_";

    this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, {
      publishAudio: true,
      publishVideo: false,
      videoSource: false,
      insertMode: 'append',
      mirror: false,
      name: name + data.userConnectionId
    }, (err) => {
      if (err) {
        console.log("cancel audio connector", err);
      }
      else {
          
      }
    });
   
    this.publish();

    //this.publisher.on("streamCreated", event => {
    //  console.log("STREAM CREATED PUBLIHSER AUDIO CONNECTOR"); 
    //});

    //this.publisher.on("streamDestroyed", event => {
    //  console.log("STREAM DESTROYED PUBLIHSER AUDIO CONNECTOR", event.stream.name);
    //  //this.onStopAudioConnector.emit(event.stream);
    //  this.publisher.destroy();
    //});
  }

  publish() {
    console.log("publish", this.publisher.id);
    this.session.publish(this.publisher, (err) => {
      if (err) {
        alert(err.message);
        this.publishing = false;
        this.setOpacity();
        if (this.publisher.stream?.videoType == constants.StreamVideoType.Screen) this.onScreenShareStop.emit();
      } else {
        console.log("publish ok");
        this.publishing = true;
        this.opacity = constants.OnVideoOpacity;
      }
    });
  }

  setOpacity(): void {
    if (this.isExpert) {
      this.opacity = constants.NoVideoOpacityExpert;
    }
    else {
      this.opacity = constants.NoVideoOpacityTechnician;
    }  
  }

  updateAudio(value: boolean) {
    this.publisher.publishAudio(value);
  }

}
