<h1 mat-dialog-title>{{ 'ASSISTANCES_MODAL.TITLE' | translate }}</h1>
<div mat-dialog-content>
  <div>
    <p>{{ 'ASSISTANCES_MODAL.CONTENT' | translate }}</p>
  </div>
  <form #assistanceForm="ngForm">
    <mat-form-field style="display:inline">
      <mat-label>{{ 'ASSISTANCES_MODAL.NAME' | translate }}</mat-label>
      <input matInput placeholder="{{ 'ASSISTANCES_MODAL.NAME' | translate }}" #name="ngModel" name="name" [(ngModel)]="assistance.name" required maxlength="150" whitespace>
      <mat-error *ngIf="name.invalid && name.errors?.['required']">{{ 'ERROR.FIELD_REQUIRED' | translate }}</mat-error>
      <mat-error *ngIf="name.invalid && name.errors?.['whitespace'] && !name.errors?.['required']">{{ 'ERROR.NOT_ONLY_WHITESPACE' | translate }}</mat-error>
    </mat-form-field>
    <mat-form-field style="display:inline">
      <mat-label>{{ 'ASSISTANCES_MODAL.COMMENTS' | translate }}</mat-label>
      <textarea matInput placeholder="{{ 'ASSISTANCES_MODAL.COMMENTS' | translate }}" #comments="ngModel" name="comments" [(ngModel)]="assistance.comments" maxlength="5000" whitespace></textarea>
      <mat-error *ngIf="comments.invalid && comments.errors?.['whitespace']">{{ 'ERROR.NOT_ONLY_WHITESPACE' | translate }}</mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onSubmit(assistanceForm)" [disabled]="assistanceForm.invalid">{{ 'MODAL.ACCEPT' | translate }}</button>
</div>
