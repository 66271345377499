<h1 mat-dialog-title>{{ title | translate }}</h1>

<div mat-dialog-content>
  <p>{{ message | translate}}</p>
</div>

<div *ngIf="!addButtons" mat-dialog-actions align="end">
  <button mat-button (click)="close()">{{ 'MODAL.CLOSE' | translate}}</button>
</div>

<div *ngIf="addButtons" mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true">{{'MODAL.ACCEPT' | translate}}</button>
  <button mat-button [mat-dialog-close]="false">{{'MODAL.DECLINE' | translate}}</button>
</div>

