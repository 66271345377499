import { Injectable } from '@angular/core';
import { SecurityService } from './security.service';
import { ApiService } from './communications/api.service';
import { SignalRService } from './communications/signalr.service';
import { UserDataService } from './userData.service';
import { Observable } from 'rxjs';
import * as constants from '../constants/constants';


@Injectable({
  providedIn: 'root'
})

export class LoginService {
  answer = '';
  constructor(private securityService: SecurityService, private apiService: ApiService, private signalRService: SignalRService, private userDataService: UserDataService) { }

  public loginToken(token: string): Observable<string> {
    let errorMessage = '';
    this.securityService.saveUrlToken(token);

    return new Observable<string>(observer => {
      this.apiService.loginToken(token).subscribe({
        next: data => {
          this.securityService.addSecurityToken(data);
          console.log("Login data",data);
          this.userDataService.set(data.userId, data.userName, data.role, data.roomId, data.participantId);
          this.signalRService.startSignalR().subscribe({
            next: result => {
              console.log("SignalR start result: " + result.toString())
              this.signalRService.sendSignalRConnectionData(data.userId!, data.roomId!,data.participantId);
            },
            error: result => {
              console.log("SignalR start result: " + result.toString())
            }

          });

          observer.next(constants.Result_Ok);
          observer.complete();
        },
        error: error => {
          console.error('There was an error!', error.error);
          errorMessage = 'There was an error: ' + error.error;
          observer.error(error.error);
        }
      });
    });
  }

}

