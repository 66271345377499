import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ConfigService } from 'src/app/services/config.service';
import { ApiService } from 'src/app/services/communications/api.service';
import { UserDataService } from 'src/app/services/userData.service';
import { SignalRService } from '../../services/communications/signalr.service';
import { Subscription } from 'rxjs';
import { AssistanceStateUpdateResponse } from '../../interfaces/Responses/AssistanceStateUpdateResponse';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationSnackComponent } from '../notificationSnak/notificationSnack.component';
import * as constants from 'src/app/constants/constants';
import { MatButtonModule } from '@angular/material/button';
import { ModalComponent } from '../modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { AssistanceModalComponent } from './assistance-modal/assistance-modal.component';
import { Assistance } from '../../interfaces/assistance';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MatSelectModule, MatFormFieldModule, TranslateModule, MatSnackBarModule, MatButtonModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  @Input() assistanceState!: string;

  langs: string[] = [];
  assisatancesStatesEnum: { [key: string]: number } = {};
  assisatancesStatesOptions: { key: string; value: number }[] = [];
  currentLang;
  isExpert: boolean;
  assistancesToolTip: string = "";
  assistanceStateSubscription!: Subscription;


  constructor(private translate: TranslateService, private configurationService: ConfigService, private apiService: ApiService, public userDataService: UserDataService, private signalService: SignalRService,
    private snackBar: MatSnackBar, public dialog: MatDialog) {
    this.langs = this.translate.getLangs();
    this.currentLang = this.translate.currentLang;
    this.isExpert = userDataService.Role == constants.UserRole.Expert;
    
    this.signalREventsHook();
  }

  ngOnInit(): void {
    console.log("onInit", this.assistanceState);
    if (!this.isExpert) {
      this.translate.get('ASSISTANCES.CANT_START_ASSISTANCE').subscribe((text: string) => { this.assistancesToolTip = text; });
    }else
      this.translate.get('ASSISTANCES.TOOL_TIP').subscribe((text: string) => { this.assistancesToolTip = text; });

    this.apiService.getAssistancesStates().subscribe(result => {
      result.forEach(item => { this.assisatancesStatesEnum[item.name] = item.id; });
      this.assisatancesStatesOptions = result.map(item => ({ key: item.name, value: item.id })).sort((a, b) => a.value - b.value);
    });
  }

  onSelectChange(lang: string) {
    this.translate.use(lang);
    this.configurationService.changeLanguage(lang);
  }

  getLink() {
    console.log("get link");
    this.translate.get('LINKS.GETTING').subscribe((text: string) => {
      this.openSnackBar(text);
    });

    this.apiService.getUpdatedToken(this.userDataService.Id!, this.userDataService.RoomId!).subscribe({
      next: data => {
        let link = document.location.origin + "/?token=" + data.token;
        console.log(link);
        navigator.clipboard.writeText(link);

        this.translate.get('LINKS.COPIED').subscribe((text: string) => {
          this.openSnackBar(text);
        });

      },
      error: err => {
        console.error(err.error);
      }
    });

    this.cleanLinks();
  }

  openSnackBar(message: string, duration: number = constants.snackBarDurationInSeconds) {
    this.snackBar.openFromComponent(NotificationSnackComponent, {
      duration: duration * 1000, data: { message: message }
    });
  }

  cleanLinks() {
    setTimeout(function () {
      let divLink = document.getElementById('getLink') as HTMLElement;
      divLink.innerHTML = "";
    }, 2500);
  }

  startAssistance() {
    var state = this.assisatancesStatesEnum['Open'];
    if (this.userDataService.roomInfo.assistanceId == null) {
      const closeAssistanceDialog = this.dialog.open(AssistanceModalComponent, {
        width: '50%',
        autoFocus: true
      });

      closeAssistanceDialog.afterClosed().subscribe((assistance: Assistance) => {
        if (assistance == undefined || assistance.name == '') this.assistanceState = this.userDataService.roomInfo.lastAssistanceState;
        else {
          assistance.state = state;
          this.createAssistance(assistance);
        }
      });
    }
  }

  changeAssistanceStatus(select: MatSelect) {
    select.close();
    var state = select.value;

    if (state == this.assisatancesStatesEnum['Closed'] || state == this.assisatancesStatesEnum['Cancelled']) {
      const closeAssistanceDialog = this.dialog.open(ModalComponent, {
        autoFocus: true,
        data: {
          title: 'ASSISTANCES.CLOSE_MODAL_TITLE',
          message: 'ASSISTANCES.CLOSE_MODAL_MESSAGE',
          addButtons: true
        }
      });

      closeAssistanceDialog.afterClosed().subscribe((result: boolean) => {
        if (result == true) this.sendStateUpdate(state);
        else this.assistanceState = this.userDataService.roomInfo.lastAssistanceState;
      });

    } else {
        this.sendStateUpdate(state);
    }

  }

  createAssistance(assistance: Assistance) {
    this.apiService.createAssitance(this.userDataService.roomInfo.roomId, this.userDataService.Id!, assistance).subscribe({
      next: (data: any) => {
        if (data == null || data.length == 0) {
          console.log("No chat data ", data);
          return;
        }
      },
      error: (error) => { console.error(error); }
    });
  }


  sendStateUpdate(state: number) {
    this.apiService.changeAssitanceState(this.userDataService.roomInfo.roomId, this.userDataService.Id!, this.userDataService.roomInfo.assistanceId, state).subscribe({
      next: (data: any) => {
        if (data == null || data.length == 0) {
          console.log("No chat data ", data);
          return;
        }
      },
      error: (error) => { console.error(error); }
    });
  }

  signalREventsHook() {
    this.assistanceStateSubscription = this.signalService.onAssistanceStateUpdate.subscribe((result: AssistanceStateUpdateResponse) => {
      this.assistanceState = result.assistanceState;

      this.userDataService.roomInfo.assistanceId = result.assistanceId;
      this.userDataService.roomInfo.lastAssistanceState = result.assistanceState;
    });
  }

  ngOnDestroy() {
    this.assistanceStateSubscription.unsubscribe();
  }

}
