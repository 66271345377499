<h1 mat-dialog-title>{{ 'MODAL_ASK_GUEST_EMAIL.TITLE' | translate }}</h1>
<div mat-dialog-content>
  <div>
    <p>{{ 'MODAL_ASK_GUEST_EMAIL.MESSAGE' | translate }}</p>
  </div>
  <form>
    <mat-form-field style="display:inline">
      <mat-label>{{ 'MODAL_ASK_GUEST_EMAIL.LABEL' | translate }}</mat-label>
      <input type="email" matInput [formControl]="userEmail" placeholder="{{ 'MODAL_ASK_GUEST_EMAIL.PLACEHOLDER' | translate }}">
      <mat-error *ngIf="(userEmail.hasError('email') || userEmail.hasError('completeEmail')) && !userEmail.hasError('required')">
        {{ 'MODAL_ASK_GUEST_EMAIL.VERIFICATION_EMAIL_ERROR' | translate }}
      </mat-error>
      <mat-error *ngIf="userEmail.hasError('required')">
        {{ 'MODAL_ASK_GUEST_EMAIL.REQUIRED_EMAIL_ERROR' | translate }}
      </mat-error>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="acceptNameChanged()">{{ 'MODAL.ACCEPT' | translate }}</button>
</div>
