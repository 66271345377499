
<div class="modal-header">
  <h2 mat-dialog-title>{{'TRANSCRIPTIONS_CONSENT.TITLE' | translate}}</h2>
</div>
<mat-dialog-content style="font-size: 0.9rem;">
  <p>{{'TRANSCRIPTIONS_CONSENT.TERMS_LIST' | translate}}</p>
  <ol>
    <li><b>{{'TRANSCRIPTIONS_CONSENT.TERMS1' | translate}}</b> {{'TRANSCRIPTIONS_CONSENT.TERMS1_TEXT' | translate}}</li>
    <li><b>{{'TRANSCRIPTIONS_CONSENT.TERMS2' | translate}}</b> {{'TRANSCRIPTIONS_CONSENT.TERMS2_TEXT' | translate}}</li>
    <li [innerHTML]="('<b>' + ('TRANSCRIPTIONS_CONSENT.TERMS3' | translate) + '</b>! ' + ('TRANSCRIPTIONS_CONSENT.TERMS3_TEXT' | translate))"></li>
    <li><b>{{'TRANSCRIPTIONS_CONSENT.TERMS4' | translate}}</b> {{'TRANSCRIPTIONS_CONSENT.TERMS4_TEXT' | translate}}</li>
  </ol>

  <p [innerHTML]="('TRANSCRIPTIONS_CONSENT.CONTACT' | translate)"></p>

  <p><b>{{'TRANSCRIPTIONS_CONSENT.QUESTION' | translate}}</b></p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true">{{'MODAL.ACCEPT' | translate}}</button>
  <button mat-button [mat-dialog-close]="false">{{'MODAL.DECLINE' | translate}}</button>
</mat-dialog-actions>
      
