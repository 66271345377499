<!--<app-loader *ngIf="showLoader" [loaderMode]="true"></app-loader>-->
<app-header [assistanceState]="assisastanceState"></app-header>
<div class="fullWaitingRoom">
  <div class="background">
    <div class="two-colums">
      <div class="blue-area participants">
        <directCall-members class="resposive-off" [stickyMode]="true"></directCall-members>
        <directCall-members class="resposive-on" *ngIf="members" [withMenu]="true" (close)="switchMembers()"></directCall-members>
        <div class="button-area">
          <div class="button" [ngClass]="{'online' : otherParticipantOnline && ((this.userDataService.roomInfo.transcriptionsOn && this.userDataService.roomInfo.transcriptionsLanguage != undefined) || !this.userDataService.roomInfo.transcriptionsOn), 'offline' : !otherParticipantOnline || (this.userDataService.roomInfo.transcriptionsOn && this.userDataService.roomInfo.transcriptionsLanguage == undefined)}" (click)="makeCall()">
            <p>{{ buttonText | translate }} <i class="fa fa-warning" *ngIf="error != ''">{{error}}</i></p>
          </div>
          <div class="round-button members-button resposive-on" (click)="switchMembers()">
            <img src="/assets/images/buttons/contacts.svg" alt="Icono">
          </div>
        </div>
      </div>
      <div class="blue-area chat">
        <directCall-chat [stickyMode]="true"></directCall-chat>
      </div>
    </div>
  </div>
</div>

